<template>
    <div>
        <div class="detail">
            <div class="details-top" v-if="isPlay">
                <div class="top-img">
                    <img :src="gameInfo.logo" alt="">
                </div>
                <div class="top-title">
                    {{gameInfo.title}}
                </div>
                <div class="top-rat">
                    <van-rate
                    v-model="gameInfo.rating"
                    readonly
                    :size="15"
                    color="#FF3434"
                    void-icon="star"
                    void-color="#eee"
                    />
                    <div class="rat">
                        {{gameInfo.rating}}
                    </div>
                </div>
                <div class="top-btn" @click="()=>{isPlay = false}">
                    PLAY
                </div>                
            </div>
            <div class="gamewin" v-else>
                <iframe
                style="width:100%"
                :src="baseUrl+gameInfo.url"
                frameborder="0"></iframe>
            </div>
            <div class="details-description">
                <div class="des-top">Description :</div>
                <div class="des-body warp-6">{{gameInfo.synopsis}}</div>
            </div>
            <div class="cont-st">
                <div class="cont-st-h">
                    <p>HOT</p>
                    <div>more</div>
                </div>
                <div class="cont-st-card" v-for="item in gameList" :key="item.id" @click="goDetails(item)">
                    <img :src="item.logo" alt="">
                    <p class="no-warp">{{item.title}}</p>
                    <van-rate
                    v-model="item.rating"
                    readonly
                    :size="10"
                    color="#FF3434"
                    void-icon="star"
                    void-color="#eee"
                    />
                </div>
                <span v-for="item in 8" :key="item+'s'"></span>
            </div>
        </div>
    </div>
</template>

<script>
import { isMobile, scrollToTop } from "@/utils/api"
import { getGameById } from "@/api/games"

    export default {
        data() {
            return {
                value:4,
                gameList:[],
                gameInfo:{},
                spanNum:0,
                isPlay:true,
                baseUrl:''
            }
        },
        mounted() {
            let id = this.$route.query.id

            this.getGameInfo(id);

            if(isMobile()){
                this.spanNum = 3-2
            }else{
                this.spanNum = 11-2
            }

            // this.baseUrl = process.env.VUE_APP_BASE_GAME
            this.baseUrl = 'https://'+window.location.hostname

            scrollToTop(0);
        },
        methods: {
            async getGameInfo(id){
                let { game,similarGames } = await getGameById({id})
                this.gameInfo = game;
                this.gameList = similarGames;
            },

            goDetails(item){
                this.gameInfo = item;
                this.isPlay = true;
                scrollToTop(0);

            }
        },
    }
</script>

<style lang="less" scoped>
.detail{
    padding: 0 20px;
    box-sizing: border-box;
    .details-top{
        margin-bottom: 22px;
        div{
            width: 200px;
            margin: 0 auto;
            text-align: center;
        }
        .top-img{
            width: 200px;
            height: 200px;
            border-radius: 24px;
            margin:24px auto 4px;
            overflow: hidden;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .top-title{
            height: 22px;
            margin-bottom: 6px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #1E1B4C;
            line-height: 22px;
            font-style: normal;
        }
        .top-rat{
            width: 115px;
            margin-bottom: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            .rat{
                width: 26px;
                height: 20px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #FF0000;
                line-height: 20px;
                text-align: left;
                font-style: normal;
            }
        }
        .top-btn{
            width: 223px;
            height: 40px;
            line-height: 40px;
            background: #FF0000;
            border-radius: 20px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 16px;
            color: #FFF;
            font-style: normal;
            text-transform: uppercase;
            cursor: pointer;
        }
    }
    .gamewin{
        width: 100%;
        height: calc(100ch - 50px);
        iframe{
            width: 100%;
            height: 100%;
        }
    }
    .details-description{
        margin-bottom: 35px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        color: #1E1B4C;
        line-height: 22px;
        text-align: left;
        font-style: normal;
        .des-top{
            font-size: 16px;
        }
        .des-body{
            font-size: 14px;
        }
    }
    .cont-st{
        // padding: 0 20px;
        box-sizing: border-box;
        margin-bottom: 25px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .cont-st-h{
            width: 100%;
            height: 40px;
            background: #FF0000;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 22px;
                text-align: left;
                font-style: normal;
                text-transform: uppercase;
                margin-left: 12px;
            }
            div{
                margin-right: 12px;
                font-family: PingFangSC, PingFang SC;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 22px;
                text-align: left;
                font-style: normal;
                text-transform: uppercase;
            }
        }
        .cont-st-card{
            margin: 12px 0;
            padding-top: 6px;
            box-sizing: border-box;
            width: 106px;
            height: 152px;
            background: #FFFFFF;
            border-radius: 11px;
            text-align: center;
            img{
                width: 90px;
                height: 90px;
            }
            p{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 14px;
                color: #000000;
                line-height: 20px;
                font-style: normal;
            }
        }
        span{
            width: 106px;
        }
    }
    .no-warp{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .warp-6{
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>